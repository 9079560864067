.footer-wrapper {
    background-color: #EBEDEF;
    @media only screen and (max-width : 420px) {
        display: flex;
    }
    .content-text {
        margin: 15px 0px 20px 0px;
        font-family: Montserrat-Semibold;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #757575;
        float: left;
        width: 50%;
        padding-left: 20px;
    }
    .footer-right {
        float: right;
        margin-top: 15px;
        margin-right: 25px;
        display: flex;
        justify-content: right;
        .content-link {
            font-family: Montserrat-Semibold;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #757575;
            text-decoration: none;
        }
        .pad-right {
            margin-right: 46px;
        }
    }
}