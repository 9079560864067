.verify-identity {
    &-body {
        width: 100%;
        &-container {
            display: flex;
            flex-direction: column;
            &-details {
                display: flex;
                &-row {
                    &-content {
                        margin-top: 20px;
                        .MuiFormControl-root {
                            width: 100%;
                        }
                        .MuiFormGroup-row {
                            flex-direction: column;
                        }
                        .docGrpControl {
                            display: flex;
                            width: max-content;
                            height: fit-content;
                        }
                        .docGroupLabel {
                            width: 25%;
                        }
                        .doc-value-fields {
                            display: flex;
                            padding: 0px 0px 20px 0px;
                            .generic-data-show {
                                margin-left: 10px;
                                margin-top: 20px;
                            }
                            .uploader-win {
                                margin-left: 10px;
                                .file-display-container {
                                    overflow: hidden;
                                    border-radius: 4px;
                                    margin: 20px 0px 20px 0px;
                                }
                                
                                .file-status-bar {
                                    width: 100%;
                                    vertical-align: top;
                                    position: relative;
                                    line-height: 15px;
                                    background: rgb(255, 255, 255);
                                    border-radius: 4px;
                                    border: 1px solid rgb(221, 224, 233);
                                    display: flex;
                                    justify-content: space-between;
                                }
                                
                                .file-status-bar > div {
                                    overflow: hidden;
                                    display: flex;
                                }
                                .no-doc-container {
                                    display: table;
                                    min-height: 200px;
                                    border: 2px dashed #2d7ff9;
                                    border-radius: 15px;
                                    min-height: 200px;
                                    min-width: 100%;
                                    .no-doc-text {
                                        display: table-cell;
                                        text-align: center;
                                        vertical-align: middle;
                                        font-family: Lato-Bold;
                                        color: #343C44;
                                        font-size: 16px;
                                        font-weight: 100;
                                        width: 100%;
                                    }
                                }
                                
                                .file-type {
                                    display: inline-block !important;
                                    position: absolute;
                                    font-size: 12px;
                                    font-weight: 700;
                                    line-height: 13px;
                                    margin-top: 25px;
                                    padding: 0 4px;
                                    border-radius: 2px;
                                    box-shadow: 1px 1px 2px #abc;
                                    color: #fff;
                                    background: #0080c8;
                                    text-transform: uppercase;
                                }
                                
                                .file-name {
                                    display: inline-block;
                                    vertical-align: top;
                                    margin-left: 5px;
                                    margin-top: 15px;
                                    color: #042D76;
                                }
                                
                            }
                            .MuiFormControl-root {
                                margin: 10px 10px 10px 10px !important;
                            }
                            .MuiAutocomplete-root {
                                margin: 0px 0px 0px 0px !important;
                            }
                        }
                        .no-data-label {
                            font-family: Lato-Bold;
                            color: #343C44;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 100;
                            min-height: 26px;
                            width: 100%;
                            margin: 3px 0 1px;
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }

    }
}