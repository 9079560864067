.header-wrapper {
    box-shadow: none !important;
}
.MuiAppBar-colorPrimary {
    background-color: #FFFFFF !important;
    border: none;
}
.header-icon {
    width: 40px;
    float: right;
    height: 40px;
    top: 10px;
    .profile-div{
        background-color: #C4C4C4;
        height: 45px;
        width: 45px;
        border-radius: 100px;
    }
}