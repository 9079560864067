.menu {
    border: none;
    .logo {
        width: 135px;
        height: 14px;
        margin: 30px 0px 30px 100px;
    }
    .menu-item {
        margin: 0px 0px 15px 45px;
        right: 0%;
        top: 0%;
        bottom: 0%;
        & a {
            text-decoration: none;
        }
        :first {
            padding-top: 100px;
        }
    }
    .menu-item-icon {
      color: #757575;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .menu-item-text {
        font-family: Montserrat-Semibold;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        color: #757575;
    }
}
.MuiTypography-body1 {
    left: 15%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    max-width: 55%;
    margin-bottom: 15px;
    border-radius: 31.5px;
    font-family: Montserrat-Semibold !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 18px !important;
    display: flex !important;
}

.MuiGrid-root {
    padding-top: 5px;
}

.MuiList-root {
    a {
        text-decoration: none;
    }
    padding-top: 65px;
}
.MuiIconButton-label {
    color: #2D7FF9;
}

.logout-item {
    color: #757575;
}
.MuiListItem-root {
    :hover {
        background-color: transparent !important;
    }
}

.MuiButtonBase-root .MuiListItem-root .MuiListItem-gutters .MuiListItem-button {
    padding-top: 65px;
}

.MuiList-root .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
    span {
        color: #2D7FF9;
    }
    svg {
        fill: #2D7FF9;
    }
    :hover {
        background-color: transparent !important;
    }
}
