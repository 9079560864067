.personal-info {
    &-body {
        width: 100%;
        &-container {
            &-details {
                padding: 0px 0px 10px 0px;
                &-row {
                    width: 100%;
                    display: flex;
                    @media only screen and (max-width : 420px) {
                        flex-direction: column;
                    }
                    .question-field-personal {
                        border: 2px solid #757575;
                        padding: 15px;
                        margin: 30px 5px 5px 0px;
                        border-radius: 18px;
                        .check-box-ctrls {
                          margin: 15px 20px 15px 20px;
                        }
                        .MuiFormGroup-root {
                            display: inherit;
                        }
                    }
                    .MuiFormControlLabel-label {
                        max-width: 100%;
                        width: 100%;
                    }
                    .p-t-30 {
                        padding-top: 30px;
                    }
                    .add-middleName {
                        width: 5%;
                        margin-left: 3px;
                        margin-top: 20px;
                        cursor: pointer;
                        float: right;
                    }
                }
                &-row-submit {
                    width: 100%;
                    .p-t-32 {
                        padding-top: 32px;
                    }
                }
                .empty-row {
                    padding-top: 40px;
                }
            }
            .empty-row {
                padding-top: 40px;
            }
        }

    }
}

.fullWidth {
    width: 100%;
    margin: 20px 30px 0px 0px;
}

.personal-info-edit-modal {
    //display: flex;
    justify-content: center; 
    width: 100%;   
}