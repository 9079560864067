.convictions {
    &-body {
        width: 100%;
        &-container {
            &-details {
                display: flex;
                &-row {
                    &-content {
                        
                    }
                    .check-box-ctrls {
                        margin: 15px 15px 15px 0px;
                        width: 100%;
                    }
                    .convictns-desc {
                        width: 100%;
                        margin: 0px 15px 15px 0px;
                    }
                }
            }
        }

    }
}