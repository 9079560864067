#dashboard-wrapper {
    width: 100%;
    padding-left: 58px;
    padding-top: 25px;
    .title {
        font-family: Montserrat-Bold;
        font-weight: bold;
        font-size: 65px;
        line-height: 65px;
        color: #2D7FF9;
        padding-left: 20px;
        padding-bottom: 5px;
    }
}