#check-summary-view-wrapper {
    width: 100%;
    background-color: #FFFFFF;
    min-height: 625px;
    .compliance-container {
        .container-content {
            width: 100%;
            display: flex;
            align-items: flex-start;
            .container-content-form {
                width: 100%;
                display: flex;
                flex-direction: column;
                .editor-title {
                    text-align: center;
                    padding: 15px 0px 10px 0px;
                    font-family: Montserrat-Bold;
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 40px;
                    color: #343c44;
                    float: left;
                }
            }
            .container-max-height {
                overflow-y: auto;
            }
        }
    }
}
#backArrow {
    margin-left: 10px;
}
.action-panel-btns {
    padding: 10px 0px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background: white;
    border-top: 1px solid #2D7FF9;
}

.countersign {
    .primary-button{
        width: 300px!important;
    }
}

.field-label {
    font-family: Montserrat-Regular;
    color: #757575;
    text-align: center;
    font-size: 18px;
    font-weight: 100;
    min-height: 26px;
    width: 100%;
    margin: 3px 0 1px;
    word-wrap: break-word;
}

.value-label {
    font-family: Lato-Bold;
    color: #343C44;
    font-size: 18px;
    font-weight: 100;
    min-height: 26px;
    width: 100%;
    margin: 3px 0 1px;
    word-wrap: break-word;
}

.editor-forms {
    max-height: 550px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 8px;
        margin-left: 3px;
    }
    &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background: rgba(21, 53, 73, 0.2);
    border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
    background: rgba(21, 53, 73, 0.2);
    }
}