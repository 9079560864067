  @font-face {
    font-family: Montserrat-Black;
    src: url("./Assets/fonts/Montserrat/Montserrat-Black.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-BlackItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-BlackItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-Bold;
    src: url("./Assets/fonts/Montserrat/Montserrat-Bold.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-BoldItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-BoldItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-ExtraBold;
    src: url("./Assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-ExtraBoldItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-ExtraLight;
    src: url("./Assets/fonts/Montserrat/Montserrat-ExtraLight.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-ExtraLightItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-Italic;
    src: url("./Assets/fonts/Montserrat/Montserrat-Italic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-Light;
    src: url("./Assets/fonts/Montserrat/Montserrat-Light.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-LightItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-LightItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-Medium;
    src: url("./Assets/fonts/Montserrat/Montserrat-Medium.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-MediumItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-MediumItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-Regular;
    src: url("./Assets/fonts/Montserrat/Montserrat-Regular.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-SemiBold;
    src: url("./Assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-SemiBoldItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-Thin;
    src: url("./Assets/fonts/Montserrat/Montserrat-Thin.ttf") format('truetype');
  }

  @font-face {
    font-family: Montserrat-ThinItalic;
    src: url("./Assets/fonts/Montserrat/Montserrat-ThinItalic.ttf") format('truetype');
  }


  // ********** LATO Font ************//

  @font-face {
    font-family: Lato-Black;
    src: url("./Assets/fonts/Lato/Lato-Black.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-BlackItalic;
    src: url("./Assets/fonts/Lato/Lato-BlackItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-Bold;
    src: url("./Assets/fonts/Lato/Lato-Bold.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-BoldItalic;
    src: url("./Assets/fonts/Lato/Lato-BoldItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-Italic;
    src: url("./Assets/fonts/Lato/Lato-Italic.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-Light;
    src: url("./Assets/fonts/Lato/Lato-Light.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-LightItalic;
    src: url("./Assets/fonts/Lato/Lato-LightItalic.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-Regular;
    src: url("./Assets/fonts/Lato/Lato-Regular.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-Thin;
    src: url("./Assets/fonts/Lato/Lato-Thin.ttf") format('truetype');
  }

  @font-face {
    font-family: Lato-ThinItalic;
    src: url("./Assets/fonts/Lato/Lato-ThinItalic.ttf") format('truetype');
  }
  
  :root {
    --font-family: Montserrat, Lato;
    --montserrat-regular: "Montserrat-Regular";
    --montserrat-light: "Montserrat-Light";
    --montserrat-thin: "Montserrat-Thin";
    --montserrat-italic: "Montserrat-Italic";
    --montserrat-medium: "Montserrat-Medium";
    --montserrat-semibold: "Montserrat-Semibold";
    --montserrat-bold: "Montserrat-Bold";
    --montserrat-extrabold: "Montserrat-Extrabold";
    --montserrat-blackItalic: "Montserrat-BlackItalic";
    --montserrat-boldItalic: "Montserrat-BoldItalic";
    --montserrat-lightItalic: "Montserrat-LightItalic";
    --montserrat-extraboldItalic: "Montserrat-ExtraBoldItalic";
    --montserrat-extralight: "Montserrat-ExtraLight";
    --montserrat-extralightItalic: "Montserrat-ExtraLightItalic";
    --montserrat-mediumItalic: "Montserrat-MediumItalic";
    --montserrat-black: "Montserrat-Black";
    --montserrat-semiboldItalic: "Montserrat-SemiboldItalic";
    --montserrat-thinItalic: "Montserrat-ThinItalic";
    --lato-black: "Lato-Black";
    --lato-blackItalic: "Lato-BlackItalic";
    --lato-bold: "Lato-Bold";
    --lato-boldItalic: "Lato-BoldItalic";
    --lato-Italic: "Lato-Italic";
    --lato-light: "Lato-Light";
    --lato-lightItalic: "Lato-LightItalic";
    --lato-regular: "Lato-Regular";
    --lato-thin: "Lato-Thin";
    --lato-thinItalic: "Lato-ThinItalic";
  }
  
  * {
    box-sizing: border-box;
  }
  
  body {
    padding-right: 0px !important;
    overflow: auto !important;
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: transparent !important;

      &::-webkit-scrollbar {
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: rgba(140, 202, 240, 0.2);;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(140, 202, 240, 0.2);;
    }

    /* ----------- Non-Retina Screens ----------- */
    @media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 1) { 
      //max-width: 1200px;
    }

    /* ----------- Retina Screens ----------- */
    @media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (min-resolution: 192dpi) { 
      //max-width: 1200px;
    }

    /* ----------- iPhone 6+, 7+ and 8+ ----------- */

    /* Portrait and Landscape */
    @media only screen 
    and (min-device-width: 414px) 
    and (max-device-width: 736px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
      max-width: 414px;
    }

    /* Portrait */
    @media only screen 
    and (min-device-width: 414px) 
    and (max-device-width: 736px) 
    and (-webkit-min-device-pixel-ratio: 3)
    and (orientation: portrait) { 
      max-width: 414px;
    }

    /* Landscape */
    @media only screen 
    and (min-device-width: 414px) 
    and (max-device-width: 736px) 
    and (-webkit-min-device-pixel-ratio: 3)
    and (orientation: landscape) { 
      max-width: 414px;
    }

    /* ----------- iPhone X ----------- */

    /* Portrait and Landscape */
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
      max-width: 375px;
    }

    /* Portrait */
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 3)
    and (orientation: portrait) { 
      max-width: 375px;
    }

    /* Landscape */
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) 
    and (-webkit-min-device-pixel-ratio: 3)
    and (orientation: landscape) { 
      max-width: 375px;
    }
    .main-Screen {
      background-color: transparent !important;
      padding: 0px 30px 0px 30px;
      @media only screen and (min-width : 1020px) {
        width: 100%;
        min-height: 100%;
      }
      @media only screen and (max-width : 420px) {
        width: 100%;
        min-height: 100%;
      }
      body {
        background-color: transparent !important;
      }
    }
    .no-layout-Screen {
      background-color: transparent !important;
      @media only screen and (min-width : 1020px) {
        width: 100%;
        min-height: 100%;
      }
      @media only screen and (max-width : 420px) {
        width: 100%;
        min-height: 100%;
      }
      body {
        background-color: transparent !important;
      }
    }
    
    @media (min-width: 600px) {
     .MuiContainer-root {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    @media (max-width:800px){
      #check-details-wrapper{
        width:100%
      }
      .action-panel-btns{
        flex-direction: column;
      }
    }

    .MuiFormControl-marginDense {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      margin-top: 2px !important;
    }
    .MuiInputBase-formControl {
      margin-top: 0px !important;
      height: 44px !important;
    }
    .MuiAutocomplete-input {
      padding: 0px 4px !important;
    }

    .oneHalfWidth {
      @media only screen and (min-width : 1020px) {
        width: 46%;
      }
      margin: 10px 40px 0px 0px;
      float: left;
    }
    .oneFourthWidth {
      width: 30%;
      margin: 30px 10px 0px 0px;
      float: left;
    }
    .threeFourthWidth {
      width: 70%;
      margin: 20px 0px 0px 0px;
      float: left;
    }
    .centralize-content {
      height: 100%;
      justify-content: center;
    }
    .MuiContainer-root {
      //padding-right: 15px;
      body {
        background-color: transparent !important;
      }
    }
    .main-wrapper {
      width: 100%;
      margin: 0px 40px 40px 50px;
      .header {
        display: flex;
        margin-bottom: 20px;
        .back{
          text-align: left;
          .navigate {
              height: 40px;
              width: 30px;
              cursor: pointer;
              margin-right: 20px;
          }
          img, svg {
            height: 40px;
            fill: #2D7FF9;
            width: 30px;
          }
        }
        .title {
          font-family: Montserrat-Bold;
          font-weight: bold;
          font-size: 34px;
          line-height: 43px;
          margin-left: 15px;
          color: #343c44;
          display: flex;
          .title-icon {
            margin: 5px 0px 0px 30px;
            float: left;
            vertical-align: top;
            img{
              width: 40px;
              height: 30px;
              background: #343c44;
            }
          }
        }
      }
    }
    .container-details-row-labels {
      font-family: Montserrat-Regular;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      color: #343C44;
      margin: 10px 20px 10px 20px;
      text-align: left;
    }
    .container-details-row-value {
      font-family: Montserrat-Semibold;
    }
    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding: 2px;
    }
    .editor-component {
      background-color: transparent;
      mix-blend-mode: multiply;
      min-height: 100%;
      display: flex;
      .container-submit-btn {
        float: right;
        margin-top: 40px;
        margin-right: 0px;
        display: flex;
      }
    }
    .editor-component-title {
      @media only screen and (min-width : 1020px) {
        display: block;
      }
      @media only screen and (max-width : 420px) {
        display: flex;
      }
      padding: 20px 0px 20px 0px;
      margin-bottom: 20px;
      font-family: Montserrat-Bold;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #343c44;
      float: left;
      border-bottom: 1px solid #343C44;
      display: block;
      width: 100%;
    }
    .editor-component-body {
      font-family: Lato-Regular;
      font-size: 18px;
      font-weight: 100;
      line-height: 30px;
      color: #343C44;
      float: left;
    }
    .logout-item {
      color: #757575;
    }
    .icon-btns {
      img, svg {
        fill: rgba(45, 127, 249, 1);
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      .icon-size-big {
        width: 24px;
        margin-left: 10px;
        height: 24px;    
      }
    }
    .MuiStepLabel-labelContainer {
      .MuiStepLabel-label {
          font-family: Montserrat-SemiBold;
          margin-left: 5px;
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #343C44;
      }
      .MuiStepLabel-active {
          font-family: Montserrat-Bold;
          font-size: 16px;
      }
    }

    .MuiStepContent-root {
      font-family: Lato-Regular;
      font-size: 16px;
      color: #757575;
      word-wrap: break-word;
      width: 100%;
      padding-top: 20px;
      margin-top: 0px !important;
      border-left-width: 4px !important;
      margin-left: 10px !important;
      border-color: #2D7FF9 !important;
    }

    .MuiStepLabel-iconContainer {
      svg {
          fill: #2D7FF9;
          color: #2D7FF9;
      }
      .MuiStepIcon-text {
          fill: #2D7FF9;
          color: #2D7FF9;
      }
    }

    .MuiStepConnector-root {
      .MuiStepConnector-lineVertical {
          border-left-width: 4px;
          min-height: 30px !important;
          border-color: #2D7FF9;
      }
      .MuiStepConnector-lineHorizontal {
        border-width: 4px;
        border-color: #2D7FF9;
      }
    }

    .MuiStepConnector-vertical, .MuiStepConnector-horizontal {
      margin: 0px -11px 0px -17px;
      padding: 0px !important;
    }

    .MuiLinearProgress-root {
      height: 9px;
      border-radius: 7.5px;
    }

    .MuiLinearProgress-colorPrimary {
      background-color: #CCE8F9;
      border-radius: 7.5px;

    }
    .MuiLinearProgress-barColorPrimary {
      background-color: #2D7FF9;
      border-radius: 7.5px;
    }

    .MuiIconButton-colorPrimary {
      color: #2D7FF9 !important;
    }

    .MuiIconButton-colorSecondary {
      color: #2D7FF9 !important;
    }

    .link-btn {
      font-family: Lato-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-decoration: underline;
      line-height: 20px;
      align-items: center;
      text-align: center;
      margin-left: 5px;
      color: #2D7FF9;
    }
    .scroll-style {
      overflow-y: scroll;
      &::-webkit-scrollbar {
          width: 8px;
          margin-left: 3px;
      }
      &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
      background: rgba(21, 53, 73, 0.2);
      border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
      background: rgba(21, 53, 73, 0.2);
      }
    }
  }
  .box {
    border-radius: 25px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    padding: 15px;
}  