.MuiAutocomplete-listbox {
    border: 2px solid #2D7FF9;
    border-radius: 10px;
    overflow-x: hidden !important;

    .MuiAutocomplete-option {
        border-bottom: 1px solid #2D7FF9;
        min-height: 62px;
        cursor: pointer;

        .options-container {
            display: flex;
            flex-direction: row;
            position: relative;
            width: 100% !important;
            cursor: inherit;

            .sub-detail-1 {
                margin: 2px 0px;
                font-family: Lato-regular;
                font-size: 16px;
                height: auto;
                letter-spacing: 0px;
            }

            .sub-detail-2 {
                position: absolute;
                left: 42px;
                top: 22px;
                width: 100% !important;
                color: rgb(173, 184, 191);
                font-family: Lato-regular;
                font-size: 12px;
                font-weight: 500;
                height: 12px;
                letter-spacing: 0px;
            }
        }

        &[aria-selected='true'] {
            background: #F1F7FF;
        }

        &[data-focus='true'] {
            border-top: 1px solid #2D7FF9;
            background: #F1F7FF;
        }
    }
}