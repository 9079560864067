#check-details-wrapper {
    width: 100%;
    .details-container {
        flex-basis: 50%;
        width:100%;
        .container-content {
            .accordion-bar {
                margin: 0px;
            }
        }
    }
    .d-flex {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    .ml-20 {
        margin-left: 20px;
    }
}

.container-details-row-labels {
    display: flex;
    justify-content: space-between;
}

.check-personal-details-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
}

#check-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    margin: 20px 0 20px 0;
    .request-details-content {
        text-align: left !important;
        .check-action-option-btn {
            display: flex;
            flex-direction: column;
            .actn-btn {
                display: flex;
                margin-right: 121px;
                margin-top: 3px;
                .span-txt {
                    margin-right: 10px;
                    width: 250px;
                }
                .svg-el{
                    width: 50px;
                    margin-right: 140px;
                }
            }
            button {
                background-color: #FFFFFF;
                border: 2px solid #2D7FF9;
                border-radius: 25px;
                margin: 5px 0px 5px 0px;
                svg {
                    fill: #2D7FF9;
                }
                span {
                    background: rgba(0,0,0,.75);
                    padding: 4px;
                    vertical-align: sub;
                    float: right;
                    border-radius: 2px;
                    color: #fff;
                    font-size: 13px;
                    box-shadow: 0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%);
                }
            }
        }
    }
    .question-field {
        margin-top: 23px;
        margin-left: -20px;
        .container-details-row-labels {
            font-family: "Montserrat-Regular";
            font-weight: normal !important;
            font-size: 14px;
        }
    }
}
.header {
    text-align: center;
    flex-basis: 40%;
    width: 40%;
    background-color: #FFFFFF;
    margin-right: 18px;
    height: auto;
    min-height: 625px;
    .title {
        font-family: Montserrat-bold;
        font-style: normal;
        font-size: 26px;
        line-height: 40px;
        color: #343c44;
    }
}

.align-self-end {
    width: 100% !important;
}

.check-details-header-title {
    margin-top: 15px;
    margin-bottom: 10px;
}

.request-change {
    .MuiInputBase-formControl {
        margin-top: 0px !important;
        height: auto !important;
    }
}

.request-change-modal{
    height: 80%;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    margin-top: 20px!important;
}

.modal-container .modal-inner .section-header{
    padding: 0!important;
}

.edit-container-labels {
    font-family: Montserrat-Semibold;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #343C44;
    margin: 15px 20px 15px 20px;
    text-align: left;
}

#request-results {
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
}