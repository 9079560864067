
.doc-vwr {
    div {
        background-image: none !important;
        // background-color: transparent !important;
        // background: none !important;
    }
}
.file-image-container {
    width: 100% !important;
    height: 100% !important;
}

.file-image-container img {
    width: auto;
    max-width: 380px;
    height: auto;
    max-height: 280px;
    object-fit: contain;
}