.worked-abroad {
    &-body {
        width: 100%;
        &-container {
            &-details {
                display: flex;
                &-row {
                    &-content {
                    }
                    .check-box-ctrls {
                        margin: 15px 15px 15px 0px;
                        float: left;
                    }
                }
            }
        }

    }
}

.desc-text-area {
    .MuiOutlinedInput-multiline {
        height: 110px !important;
        .MuiOutlinedInput-inputMultiline {
              height: 100px !important;
              overflow: unset !important;
          }
    }
}