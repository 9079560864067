.button-parent {
    height: 50px;
    width: 200px;
    font-size: 20px;
    font-family: Lato-Regular;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
    margin-left: 24px;
    border-radius: 25px;
    outline: none;
    border: 1px solid #2D7FF9;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:hover {
      box-shadow: 0px 2px 4px rgba(45, 127, 249, 0.3);
    }
  }
  
  .primary-button {
    background-color: #2D7FF9;
    color: #FFFFFF;
  }
  
  .secondary-button {
    color: #2D7FF9;
    background-color: #FFFFFF;
  }
  
  .primary-disabled {
    color: #FBFBFB !important;
    background: #E0E0E0 !important;
    border: 2px solid #E0E0E0;
    border-radius: 25px;
    cursor: inherit !important;
  }
  
  .secondary-disabled {
    color: #FBFBFB !important;
    border: 1px solid #C4C4C4;
    background: #E0E0E0 !important;
    border-radius: 25px;
    cursor: inherit !important;
  }
  