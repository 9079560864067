.routes-container {
    position: relative;
    min-height: 100%; 
    .error-container {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        text-align: center;
        .text-container {
            //text-align: center;
            .message-text {
                font-family: Montserrat-bold;
                font-style: normal;
                font-size: 36px;
                margin-top: 30px;
                line-height: 61px;
                color: #2d7ff9;
                padding-left: 20px;
                padding-bottom: 20px;
            }
        }
    }
}